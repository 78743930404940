<template>
  <v-app
    class="rs-public-app"
    color="transparent"
    :style="{ backgroundImage: 'url(' + bgImage + ')' }"
  >
    <PublicMainAppBar
      color="transparent"
      :navItems="navItems"
      :navShowSignInButton="navShowSignInButton"
    />
    <v-main>
      <slot />
    </v-main>
    <Footer />
  </v-app>
</template>
<script>
import Footer from "@/components/Footer";
import PublicMainAppBar from "@/components/PublicMainAppBar";

export default {
  props: {
    navItems: {
      type: Array,
      default() {
        return [];
      },
    },
    navShowSignInButton: {
      type: Boolean,
    },
  },
  components: {
    Footer,
    PublicMainAppBar,
  },
  data() {
    return {
      bgImage: require("@/assets/liquid-cheese-bg.svg"),
      // bgImage: "https://via.placeholder.com/1200",
    };
  },
};
</script>

<style lang="scss" scoped>
.rs-public-app {
  &.theme--light.v-application {
    background-repeat: no-repeat;
    background-size: 100%;
  }
}

.v-toolbar {
  &.v-app-bar--is-scrolled {
    background: rgba(white, 0.8) !important;
    backdrop-filter: blur(3px);
  }
}
</style>
