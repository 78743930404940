<template>
  <v-app-bar
    app
    fixed
    :color="color"
    elevate-on-scroll
    elevation="18"
    class="rs-main-nav-bar"
  >
    <v-container class="d-flex align-center">
      <v-spacer v-if="!navItems"></v-spacer>
      <v-toolbar-title>
        <router-link to="/">
          <v-img
            to="/"
            lazy-src="@/assets/risk-score-logo-full-blue.svg"
            :max-width="250"
            src="@/assets/risk-score-logo-full-blue.svg"
            class="rs-logo"
            contain
          ></v-img>
        </router-link>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <div>
        <template v-if="navItems">
          <div v-for="c in navItems" :key="c">
            <NavItem :componentName="c" />
          </div>
        </template>

        <v-btn
          v-if="navShowSignInButton"
          text
          @click.prevent="handleSignInOut"
          >{{ currentUser ? "Sign Out" : "Sign In" }}</v-btn
        >
      </div>
    </v-container>
  </v-app-bar>
</template>

<script>
import { Auth } from "aws-amplify";
import { mapGetters } from "vuex";
import NavItem from "@/components/NavItem";

export default {
  props: {
    color: {
      type: String,
      default: "white",
    },
    navItems: {
      type: Array,
      default() {
        return [];
      },
    },
    navShowSignInButton: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    NavItem,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["currentUser"]),
  },
  methods: {
    handleSignInOut() {
      if (this.currentUser) {
        Auth.signOut()
          .then(() => {
            // Redirect handled in App.vue
          })
          .catch((error) => {
            console.log("ERROR SIGNING OUT", error);
          });
      } else {
        this.$router.push({ name: "SignIn" });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@media print {
  .rs-main-nav-bar {
    display: none;
  }
}
</style>
